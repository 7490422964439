import React from "react"
import Layout from "../../Components/pageComponents/contentContainer"
import { Container } from "reactstrap"

import { useStaticQuery, graphql } from "gatsby"
// import SolutionInfoSection from "../../Components/pageComponents/solutionsInfoSection"
import Img from "gatsby-image"


import style from "./styles.module.css"

export default function BusinessManagementSolutions() {

  const Image = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "solutions/Building  Management Systems/Building Management Systems Banner.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pub: file(relativePath: {eq: "solutions/Building  Management Systems/Building Equipped with BMS Stock.png"}) {
        childImageSharp {
          fixed(width: 550) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }`
  )
  // const description = "A building equipped with BMS is automated and is capable of monitoring, controlling, and managing building activities such as:";

  return (
    <Layout image={Image.banner.childImageSharp.fluid}
      title="Building Management Systems">
      <Container
        fluid
        // className="pt-5 pb-5 pl-5 pr-5"
        style={{
          background: `rgb(243, 243, 243)`,
          paddingTop: `2rem`,
          paddingBottom: `3rem`,

        }}
      >
        <h1 className="mx-auto mb-4 heading1 p-3 text-center">
          <span>Solutions we offer</span>
        </h1>

        <div className={style.audioVideoContainer}>

          <div
            style={{
              width: `450px`, marginLeft: `20px`,
              marginRight: `20px`,
              lineHeight: `30px`,
            }}>
            <h2 style={{ marginBottom: `15px` }}>Building Management Systems</h2>
            <ul>
              <li>Building Controls</li>
              <li>Facility Management</li>
              <li>Building Alarm Management</li>
              <li>Security and Safety Management</li>
              <li>Fire Systems Management</li>
              <li>Energy and Power System Management</li>
              <li>Mechanical and Electrical Control and Management</li>
              <li>Etc.</li>
            </ul>
          </div>
          <div
            style={{
              width: `450px`, marginLeft: `20px`,
              marginRight: `20px`,
              lineHeight: `30px`,
            }}>
            <Img fixed={Image.pub.childImageSharp.fixed} />
          </div>

        </div>
      </Container>

    </Layout>

  )
}